// データテーブルを扱うための共通処理等
import "datatables.net-fixedheader"
import "datatables.net-bs4"
import "datatables.net-responsive-bs4"
import "datatables.net-colreorder"

// 一部環境でpaginationが壊れる問題を修正するために強制的に再定義
(function(b){"function"===typeof define&&define.amd?define(["jquery","datatables.net"],function(a){return b(a,window,document)}):"object"===typeof exports?module.exports=function(a,d){a||(a=window);if(!d||!d.fn.dataTable)d=require("datatables.net")(a,d).$;return b(d,a,a.document)}:b(jQuery,window,document)})(function(b,a,d,m){var f=b.fn.dataTable;b.extend(!0,f.defaults,{dom:"<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
renderer:"bootstrap"});b.extend(f.ext.classes,{sWrapper:"dataTables_wrapper dt-bootstrap4",sFilterInput:"form-control form-control-sm",sLengthSelect:"custom-select custom-select-sm form-control form-control-sm",sProcessing:"dataTables_processing card",sPageButton:"paginate_button page-item"});f.ext.renderer.pageButton.bootstrap=function(a,h,r,s,j,n){var o=new f.Api(a),t=a.oClasses,k=a.oLanguage.oPaginate,u=a.oLanguage.oAria.paginate||{},e,g,p=0,q=function(d,f){var l,h,i,c,m=function(a){a.preventDefault();
!b(a.currentTarget).hasClass("disabled")&&o.page()!=a.data.action&&o.page(a.data.action).draw("page")};l=0;for(h=f.length;l<h;l++)if(c=f[l],b.isArray(c))q(d,c);else{g=e="";switch(c){case "ellipsis":e="&#x2026;";g="disabled";break;case "first":e=k.sFirst;g=c+(0<j?"":" disabled");break;case "previous":e=k.sPrevious;g=c+(0<j?"":" disabled");break;case "next":e=k.sNext;g=c+(j<n-1?"":" disabled");break;case "last":e=k.sLast;g=c+(j<n-1?"":" disabled");break;default:e=c+1,g=j===c?"active":""}e&&(i=b("<li>",
{"class":t.sPageButton+" "+g,id:0===r&&"string"===typeof c?a.sTableId+"_"+c:null}).append(b("<a>",{href:"#","aria-controls":a.sTableId,"aria-label":u[c],"data-dt-idx":p,tabindex:a.iTabIndex,"class":"page-link"}).html(e)).appendTo(d),a.oApi._fnBindAction(i,{action:c},m),p++)}},i;try{i=b(h).find(d.activeElement).data("dt-idx")}catch(v){}q(b(h).empty().html('<ul class="pagination"/>').children("ul"),s);i!==m&&b(h).find("[data-dt-idx="+i+"]").focus()};return f});

// 検索ボックスに入れられる文字列の最大長
const MAX_SEARCH_LENGTH = 500;

// 共通設定
$.extend( true, $.fn.dataTable.defaults, {
  'dom' : "<'row'<'col-sm-12 col-md-1'><'col-sm-12 col-md-11 zindex-over-ch'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
  'pageLength': 100,
  'lengthChange': false,
  'deferRender': true,
  'scroller': true,
  'processing': true,
  'createdRow': (row, _, __) => $(row).addClass("clickable"),
  'autoWidth': false, // falseにすることでテーブル幅が固定にならない
  'stateSave': true,
  'stateDuration': 0, // sessionStorageからlocalStorageに移動して、永久保存できるようにする
  'language': {
    "emptyTable": "データがありません",
    "info": " _TOTAL_ 件中 _START_ 件目から _END_ 件目まで表示",
    "infoEmpty": " 0 件中 0 件目から 0 件目まで表示",
    "infoFiltered": "（全 _MAX_ 件）",
    "infoThousands": ",",
    "lengthMenu": "_MENU_ 件表示",
    "loadingRecords": "読み込み中...",
    "processing": "処理中...",
    "search": "検索:",
    "zeroRecords": "一致するデータがありません",
    "paginate": {
      "first": "先頭",
      "last": "最後",
      "next": "次",
      "previous": "前"
    },
    "aria": {
        "sortAscending": ": 列を昇順に並べ替えるにはアクティブにする",
        "sortDescending": ": 列を降順に並べ替えるにはアクティブにする"
    },
    "buttons": {
      "colvis": "表示列"
    }
  }
});
// ページングに表示される数値の数("..."を含む)
$.fn.dataTable.ext.pager.numbers_length = 9;

export class DatatablesHandler {
  constructor($table, dataUrl = null, options = {}) {
    this.$table = $table;
    this.$table.data('dt_handler', this);
    this.dataUrl = dataUrl; // データ取得先のURL
    this.options = options;
    this.initializeDatatable();
  }

  // Datatableの初期化処理。2回呼ぶとエラーになるので、コンストラクタ以外で呼ばないこと
  initializeDatatable() {
    if (this.dataUrl) {
      this.initializeAjaxDatatable();
    } else {
      this.initializeNonAjaxDatatable();
    }
    this.setMaxLengthSearch();
    this.setLazySearch();
    this.setScrollTopEventHandler();
    this.setUpFixedHeader(); // ヘッダ固定用の処理
  }

  initializeNonAjaxDatatable() {
    this.dataTable = this.$table.DataTable(this.options);
  }

  initializeAjaxDatatable() {
    const options = $.extend(true, {}, {
      'serverSide': true,
      'fixedHeader': false,
      'ajax': {
        'url' : this.dataUrl,
        'type' : 'POST',
        'data' : (d) => $.extend( {}, d, { '_method' : 'GET' } )
      }
    }, this.options);
    this.dataTable = this.$table.DataTable(options);
  }

  // 検索ボックスの長さの最大値を設定する
  setMaxLengthSearch() {
    let $search = this.$table.closest('.dataTables_wrapper').find('.dataTables_filter input[type="search"]');
    $search.attr("maxlength", MAX_SEARCH_LENGTH);
  }

  // 遅延検索をするためのイベント設定
  // DataTablesのオプション(searchDelay)は検索発火の間隔を設定できるオプションで
  // "最後のキー入力からn秒後に発火"というオプションでは無いためここで独自に処理を書いている
  setLazySearch() {
    let runId;
    let delay = 1000; // ミリ秒
    const dataTable = this.dataTable;
    const $filter = $("#" + this.$table.attr("id") + "_filter");
    $filter.find("input").off(".DT").on("keyup.DT", function(e) {
      // 検索ワード
      const value = this.value;
      // 最後のキー入力からdelayミリ秒後に実行するように
      clearTimeout(runId);
      runId = setTimeout(function() {
        dataTable.search(value).draw();
      }, delay);
    });
  }

  setScrollTopEventHandler() {
    let $table = this.$table;
    let changing = false;
    $table.on('page.dt', function() {
      if (changing) { // 連打対策
        return;
      }
      changing = true;
      // ページ遷移イベントが発生後最初のdrawイベントに対してのみスクロールを発火させる
      $table.one('draw.dt', function() {
        // ページングの場合に画面下のままだと不便なので上にスクロールする
        if ($table.closest('.modal').length == 0) {
          // モーダル上のデータテーブルでない場合
          // 本当はテーブル上部に移動させたいが，Windows Chromeでサイドバーが表示されない程度に幅を狭めるとoffset.topの結果が狂うので諦める
          // $('html, body').animate({ scrollTop: $table.closest('.dataTables_wrapper').offset().top }, 500);
          $('html, body').animate({ scrollTop: 0 }, 500);
        } else {
          // モーダル上のデータテーブルの場合
          // 本当はテーブル上部に移動させたいが，Windows Chromeでサイドバーが表示されない程度に幅を狭めるとoffset.topの結果が狂うので諦める
          $table.closest('.modal').animate({ scrollTop: 0 }, 500);
        }
        changing = false;
      });
    });
  }

  reloadAjaxDatatable(...args) {
    this.dataTable.ajax.reload(...args);
  }

  // trをクリックした時のイベントをアタッチする
  attachRowClickEvent(handler) {
    this.$table.find('tbody').off('click', 'tr').on('click', 'tr', handler);
  }

  // セルをクリックした時のイベントをアタッチする
  attachCellClickEvent(cell_class, handler) {
    this.$table.find('tbody').off('click', cell_class).on('click', cell_class, handler);
  }

  // trをクリックした時にshowページを表示するイベントをアタッチする
  attachRowEventShowPageTransit(idIndex, urlPrefix) {
    let dataTable = this.dataTable;
    this.attachRowClickEvent(function(event) {
      const clicked_tr = this
      const isMobile = $(window).width() <= 991;
      const hasDtrControl = $(event.target).hasClass('dtr-control');
      const hasChild = $(clicked_tr).hasClass('child');

      // モバイル画面のアコーディオン関連処理時はイベントを発火させない
      if ((hasDtrControl && isMobile) || (hasChild)) {
        return;
      }

      const data = dataTable.row(clicked_tr).data();
      // 検索結果が0件で「一致するデータがありません」と表示されたtrをクリックした時にエラーが出ないようにするために
      // dataがあるかどうか確認する
      if (data) {
        window.location.href = `${urlPrefix}/${data[idIndex]}`;
      }
    });
  }

  // trをクリックした時にeditページを表示するイベントをアタッチする
  attachRowEventEditPageTransit(idIndex, urlPrefix) {
    let dataTable = this.dataTable;
    this.attachRowClickEvent(function() {
      const data = dataTable.row(this).data();
      // 検索結果が0件で「一致するデータがありません」と表示されたtrをクリックした時にエラーが出ないようにするために
      // dataがあるかどうか確認する
      if (data) {
        window.location.href = `${urlPrefix}/${data[idIndex]}/edit`;
      }
    });
  }

  // trをクリックした時にeditページを表示するイベントをアタッチする
  attachRowEventMemberPageTransit(action, idIndex, urlPrefix) {
    let dataTable = this.dataTable;
    this.attachRowClickEvent(function() {
      const data = dataTable.row(this).data();
      // 検索結果が0件で「一致するデータがありません」と表示されたtrをクリックした時にエラーが出ないようにするために
      // dataがあるかどうか確認する
      if (data) {
        window.location.href = `${urlPrefix}/${data[idIndex]}/${action}`;
      }
    });
  }

  // セルをクリックした時にGETイベントを発生させる
  attachCellEventGetAction(cell_class, idIndex, urlPrefix) {
    let dataTable = this.dataTable;
    this.attachCellClickEvent(cell_class, function() {
      const data = dataTable.row($(this).closest("tr")).data();
      // 検索結果が0件で「一致するデータがありません」と表示されたtrをクリックした時にエラーが出ないようにするために
      // dataがあるかどうか確認する
      if (data) {
        window.location.href = `${urlPrefix}/${data[idIndex]}`;
      }
    });
  }

  // カラムを表示する
  showColumns(idxes) {
    let dataTable = this.dataTable;
    $.each(idxes, function(i, v) {
      dataTable.columns(v).visible(true);
    });
  }

  // カラムを非表示にする
  hideColumns(idxes) {
    let dataTable = this.dataTable;
    $.each(idxes, function(i, v) {
      dataTable.columns(v).visible(false);
    });
  }

  // ヘッダ固定領域登録関数; 描画が完了したinitCompleteで呼び出すこと
  // 引数はjQuery Objectの配列であること
  // 基本的には固定したいヘッダを含むdiv.rowをいれるのがよい
  registerDtTools(objects) {
    if (Array.isArray(objects)) {
      this.fixed_tools = objects;
      this.fixed_tool_widths = objects.map(x => x.width());
    }
  }

  // ヘッダ固定領域の幅取得関数; initCompleteおよびresize eventのタイミングで呼び出すべき関数
  updateDtToolSizes() {
    if (Array.isArray(this.fixed_tools)) {
      this.fixed_tool_widths = this.fixed_tools.map(x => x.width());
    }
  }

  // ヘッダ固定用の処理; constructorからよばれる
  setUpFixedHeader() {
    let dataTable = this.dataTable;
    // theadの取得
    let thead = this.$table.find('thead');
    if (!thead.get(0)) {
      return; // 固定するtheadがない！
    }
    // ヘッダの表示調整用
    const div = '<div class="white-box-screen left"></div><div class="white-box-screen right"></div>';
    // このdatatablesの上位にある.table-responsiveに付加する
    this.$table.closest('.table-responsive').append(div);

    // event handlerの設定
    var that = this;
    $(document).on('scroll', function () {
      // 現在表示中のときだけ処理を実行; さもないと別タブのdatatableのheaderが消失する
      if (that.$table.is(":visible")) {
        that.fixedDtHeader();
      }
    });

    // 最後のresize eventでだけtimeout関数が実行されるようにする
    var resizer = null;
    $(window).on('resize', function () {
      // 現在表示中のときだけ処理を実行
      if (that.$table.is(":visible")) {
        // リサイズ完了後に処理を行う
        if (resizer) { // すでにtimeout処理が登録済み
          clearTimeout(resizer);
        }
        resizer = setTimeout(function(){
          that.updateDtToolSizes();
          that.initDtHeader();
          that.fixedDtHeader();
          that.scrollDtHeader();
          resizer = null;
        }, 500);
      }
    });

    this.$table.closest('.table-responsive').on('scroll', function () {
      that.scrollDtHeader();
    });
  } // setUpFixedHeader

  // ヘッダをボディに合わせて無理矢理横スクロール
  scrollDtHeader() {
    const thPosition = this.$table.find('th').css('position');
    if (thPosition != 'fixed') {
      return;
    }
    var that = this;
    this.$table.find('tbody tr:first td').each(function(index) {
      const td = $(this);
      const th = that.$table.find('thead th').eq(index);
      th.css('left', td.offset().left);
    });
  }

  // scroll時にヘッダの固定をする
  fixedDtHeader() {
    // 検索エリアもあわせて固定するので注意
    let tools = [];
    let tool_widths = [];
    if (Array.isArray(this.fixed_tools)) {
      tools = this.fixed_tools;
      tool_widths = this.fixed_tool_widths;
    }
    let toolTops = tools.map(x => x.offset().top);
    const scrollTop  = $(window).scrollTop();
    const currentToolTop = tools.length > 0 ? toolTops[0] - scrollTop : 0;
    let topOffsets = []; // 各toolsのtopOffset
    let topOffset = 0; // topOffsetsの合計
    tools.forEach((tool, i) => {
      topOffsets[i] = topOffset;
      topOffset += tool.height();
    });
    // theadの固定
    const theadTop   = this.$table.find('thead').offset().top;
    const currentTop = theadTop - scrollTop - topOffset;
    const thElements  = this.$table.find('th');

    // toolsの位置を変更
    tools.forEach((tool, i) => {
      if (toolTops[i] - scrollTop <= 0) {
        let w = tool_widths[i];
        // important指定のためにcssTextを利用する
        let txt = `min-width:${w}px!important;max-width:${w}px!important;width:${w}px!important;`;
        tool.css({'cssText': txt,
          left: tool.offset().left,
          top: topOffsets[i],
          position: 'fixed',
          zIndex: 1,
          background: 'white'
        });
      }
    }); // forEach

    if (currentTop <= 0) {
      thElements.each(function(_index) {
        const th = $(this);
        const minWidth = 'min-width:' + th.width() + 'px!important;'
        const maxWidth = 'max-width:' + th.width() + 'px!important;'
        const width    = 'width:' + th.width() + 'px!important;'
        th.css({'cssText': minWidth + maxWidth + width});
        th.css('left', th.offset().left);
      });
  
      thElements.css('top', topOffset);
      thElements.css('position', 'fixed');
      thElements.css('z-index', '1');
      thElements.css('background', 'white');

      // white-box-screenの位置をヘッダに合わせる
      this.$table.closest('.table-responsive').find('.white-box-screen').css('top', topOffset);
    } else {
      this.initDtHeader();
    }
  } // fixedDtHeader

  // 位置を初期化する; スクロールでトップに戻ったとき用
  initDtHeader() {
    const thElements  = this.$table.find('th');
    thElements.css('left', '');
    thElements.css('width', '');
    thElements.css('max-width', '');
    thElements.css('min-width', '');
    thElements.css('top', '');
    thElements.css('position', '');
    thElements.css('z-index', '');
    thElements.css('background', '');
    // white-box-screenの位置を戻す
    this.$table.closest('.table-responsive').find('.white-box-screen').css('top', 0);
    if (Array.isArray(this.fixed_tools)) {
      this.fixed_tools.forEach(tool => {
        tool.css({
          left: '', width: '', maxWidth: '', minWidth: '',
          top: '', position: '', zIndex: '', background: ''
        });
      });
    } // isArray
  } // initDtHeader

} // class DatatablesHandler

// custom render
$.fn.DataTable.render.ellipsis = function(options) {
  if (!options) options = {};
  return function(data, type, row) {
    try {
      var f = $.fn.dataTable.render.text();
      var t = f.filter(data, type, row); // サニタイズされたtext
      if (type === 'display') {
        if (data && data.length > 0) { // 空欄以外のときだけpopover用のリンクにする
          return `<a class="${options["class"] ?? ""}" style="${options["style"] ?? ""}" title="${data}">${t}</a>`;
        } else {
          return t;
        }
      }
      return t;
    } catch (ex) {
      console.log(ex);
      return data;
    }
  };
};

